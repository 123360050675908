import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Switch, Text } from "@chakra-ui/react";

import { apiFlexii } from "@/services/client";
import useOverwriteReactRemoveScrollWheelListener from "@/hooks/useOverwriteReactRemoveScrollWheelListener";

import { ConfirmationModal, Modal } from "@/components/primitives/Modals";

/**
 *
 * @param { function } onChange callback after successful state change. (eg. update data outside component, and disabling toggle)
 * @param { boolean } isDisabled Disabling toggle
 * @param { boolean } isChecked Initial state
 * @param { string } endpoint Endpoint to touch after state change
 * @param { boolean } requiresUserConfirmation Prompting user to confirm their action
 *
 */
export function Toggle({
    isDisabled,
    isChecked,
    setIsChecked,
    onChange, // This function would probably update data outside of this component and update component tree (eg. disabling option)
    endpoint,
    labels,
    requiresUserConfirmation,
    confirmationModalHeadline,
    confirmationModalDescription,
    variant,
    size
}) {
    const [ awaitingConfirmation, setAwaitingConfirmation ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    // Fix scroll wheel issue when multiple modals are open at once
    useOverwriteReactRemoveScrollWheelListener([awaitingConfirmation, error]);

    function handleUserAction() {
        setIsLoading(true);

        if (endpoint) {
            apiFlexii.post(endpoint)
                .then(response => {
                    setIsChecked(!isChecked);
                    onChange(response);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setIsLoading(false);

                    if (requiresUserConfirmation) {
                        setAwaitingConfirmation(false);
                    }
                })
        } else {
            setIsChecked(!isChecked);
            setIsLoading(false);
        }
    };

    function onToggleChange() {
        if (requiresUserConfirmation) {
            setAwaitingConfirmation(true);
        } else {
            handleUserAction();
        }
    }

    function onConfirmationClose() {
        setAwaitingConfirmation(false);
    }

    function onConfirmClick() {
        handleUserAction();
    };

    return (
        <>
            <Box sx={{
                position: "relative",
            }}>
                <Switch
                    variant={variant}
                    size={size}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    onChange={onToggleChange}
                />

                {labels.on && labels.off && (
                    <Text sx={{
                        fontSize: "h5",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: 2,
                        marginBottom: 0,
                    }}>
                        {isChecked ? labels.on : labels.off}
                    </Text>
                )}
            </Box>

            {/* Request user confirmation modal */}
            <ConfirmationModal
                title={confirmationModalHeadline}
                isOpen={awaitingConfirmation}
                onClose={onConfirmationClose}
                onConfirm={onConfirmClick}
                isLoading={isLoading}
            >
                {confirmationModalDescription}
            </ConfirmationModal>

            {/* Error modal */}
            <Modal
                title="For Flexii da – der skete en fejl!"
                isOpen={!!error}
                onClose={() => {
                    setAwaitingConfirmation(false);
                    setError(null);
                    setIsLoading(false);
                }}
            >
                Bliver du ved med at opleve problemer, så kontakt endelig vores Kundeservice – de sidder klar til at hjælpe.
            </Modal>
        </>
    );
};

Toggle.propTypes = {
    endpoint: PropTypes.string,
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string,
    }),
    requiresUserConfirmation: PropTypes.bool,
    confirmationModalHeadline: PropTypes.node,
    confirmationModalDescription: PropTypes.node,
};

Toggle.defaultProps = {
    isDisabled: false,
    isChecked: false,
    onChange: () => { },
    requiresUserConfirmation: false,
    labels: {
        on: "Til",
        off: "Fra"
    },
    confirmationModalHeadline: "Er du sikker?",
    confirmationModalDescription: "Bekræft venligst at du ønsker at færdiggøre denne handling",
}
