import {
    Flex,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Portal,
    Popover as ChakraPopover,
} from "@chakra-ui/react";
import { textSpecs } from "@/theme/foundations/typography";


export function Popover({ content, variant, size }) {
    const sizes = {
        default: {
            fontSize: "base",
            width: 8,
            height: 8,
        },
        small: {
            fontSize: "base",
            width: 5,
            height: 5,
        },
        XS: {
            fontSize: textSpecs.h6.fontSize,
            width: "1.15rem",
            height: "1.15rem",
            lineHeight: 4
        },
    };

    const variantStyling = {
        default: {
            color: "gray.dark",
            borderColor: "gray.lighter",
            backgroundColor: "white",
        },
        gray: {
            color: "white",
            borderColor: "gray.lighter",
            backgroundColor: "gray.lighter",
        },
        dark: {
            color: "blue.darkestTransparent",
            borderColor: "blue.darkestTransparent",
            backgroundColor: "transparent",
        },
        whiteActive: {
            color: "white",
            borderColor: "transparent",
            backgroundColor: "gray.lighterTransparent",
        },
        whiteInactive: {
            color: "blue.darkestTransparent",
            borderColor: "transparent",
            backgroundColor: "gray.lighterTransparent",
        },
        flexibleDark: {
            color: "blue.darkest",
            borderColor: "transparent",
            backgroundColor: "gray.lighter",
        },
        flexibleGray: {
            color: "blue.darkestTransparenter",
            borderColor: "transparent",
            backgroundColor: "gray.lighter",
        }
    };

    return (
        <ChakraPopover
            trigger="hover"
            placement='top'
            closeOnBlur={false}
            preventOverflow={true}
            openDelay={50}
            closeDelay={50}
            arrowSize={10}
        >
            <PopoverTrigger>
                <Flex
                    sx={{
                        display: "inline-flex",
                        marginLeft: 3,
                        border: "2px solid",
                        borderRadius: "full",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: 1,
                        fontWeight: "black",
                        verticalAlign: "middle",
                        flexShrink: 0,

                        ...variantStyling[ variant ],
                        ...sizes[ size ],
                    }}
                >
                    ?
                </Flex>
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverBody fontSize={"12px"}>{content}</PopoverBody>
                    <PopoverArrow />
                </PopoverContent>
            </Portal>
        </ChakraPopover>
    );
}
