import { useLayoutEffect } from "react";

// Override ReactRemoveScroll's issue behavior from Chakra 2.4.8+ when opening multiple modals at once.
// https://github.com/chakra-ui/chakra-ui/issues/7723
// https://github.com/chakra-ui/chakra-ui/issues/8269
const useOverwriteReactRemoveScrollWheelListener = dependencyArray => {
    useLayoutEffect(() => {
        const handler = event => event.stopImmediatePropagation();

        // If one of the dependencies equals to true, add the event listener
        if (dependencyArray.some(dep => !!dep)) {
            // Wheel is used for desktop devices
            document.addEventListener("wheel", handler, {
                capture: true,
                passive: true,
            });

            // Touchmove is used for mobile devices
            document.addEventListener("touchmove", handler, {
                capture: true,
                passive: true,
            });
        };

        // Cleanup function
        return () => {
            document.removeEventListener("wheel", handler);
            document.removeEventListener("touchmove", handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencyArray]);
};

export default useOverwriteReactRemoveScrollWheelListener;
